/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ToastContainer } from 'react-toastify';

import { Layout, FormContainer, StyledLabel, SectionContainer, CustomForm, StyledInput, CustomButton  } from './style';
// import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import withValidations from '../WithValidations';
import Waiting from '../Waiting';

import { saveContactUsInfo, submitContactForm } from '../../redux/actions';
import ContactSuccessPopUp from "../ContactSuccessPopUp";
// import { companySize, region} from '../../../mockData.json';
import { toast } from "react-toastify";
import { BASE_URL } from '../../utils/config';
import { companySize, region } from '../../../mockData';

const contactUsFormData =  [
  {
    "name": "firstName",
    "label": "Full Name",
  },
  // {
  //   "name": "region",
  //   "label": "Region Name",
  // },
  // {
  //   "name": "company_size",
  //   "label": "Company size",
  // }
];

const contactUsFormDataNew =  [
  {
    "name": "email",
    "label": "Work Email",
  },
  {
    "name": "companyName",
    "label": "Company Name"
  }  
];

class ContactUsForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contactStatus: false,
      companysize: [],
      companySizePointKey: null,
      value:'',
      firstName:null,
      companyName:null,
      email: null,
      phoneNumber: null,
      organizationData: null,
      dealsData: null,
      leadsData: null,
      personData: null,
      region: null,
      isValidEmail: false
    }
  }

  componentDidMount() {
    this.setState({companysize: companySize});
  }

  submitForm = (e) => {
    e.preventDefault();
    // const { submitContactForm, history } = this.props;
    // submitContactForm(history);
    // this.setState({contactStatus: true});
    // const leads = {}
    // leads.owner_id = this.state.organizationData && this.state.organizationData.data && this.state.organizationData.data.owner_id.id;
    // leads.owner_id = 12055462;
    // leads.title = this.state.companyName;
    // leads.organization_id = this.state.organizationData && this.state.organizationData.data && this.state.organizationData.data.id;
    // this.postLeads(leads);
    this.callOrganizationAPI();
    // window.ApolloMeetings.submit({
    //   map: true, 
    //   lead : {name: this.state.firstName, email: this.state.email,company_name:this.state.companyName,region:this.state.email,phone:this.state.email, company_size:this.state.email}
    // });
    window.ApolloMeetings.submit({formId : "contact-form"});
  };

  dealsApiCall = () =>{
    const { personData, organizationData, region } = this.state;
    const deals={}
    deals.title = this.state.companyName;
    deals.org_id = organizationData && organizationData.data.id;
    deals.person_id = personData && personData.data.id;
    deals.value = null;
    deals.pipeline_id=null;
    deals.stage_id=10;
    deals.user_id=null;
    deals['1a6f0a8a0801f5380befcb38deae5157a0b44426']=region;
    this.postDeals(deals)
  }


  onBlur = (e) => {
    const { saveContactUsInfo, errors } = this.props;
    if(e.target.value.trim() !== '' && !errors[e.target.name]) saveContactUsInfo(e.target.name, e.target.value);
  };

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if(charCode === 46) evt.preventDefault();
    if(charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };

  onClosePopUp = () => {
    const { history } = this.props;
    this.setState({contactStatus: false});
    history.push('/');
  }

  onChangePhoneNumber = (phoneNumber) => {
    this.setState({phoneNumber: phoneNumber});
  }
   
  onSelectPoints = (event) => {
    this.setState({
      companySizePointKey: event.target.value 
    })
  };

  callOrganizationAPI = () =>{
    const organization = {}
    organization.name = this.state.companyName;
    organization.owner_id = 12055462;
    organization['21f09c30df55118036d7bf394fc6c909d774aef5']=this.state.companySizePointKey;
    this.state.companyName && this.postOrganizaion(organization);
  }

  onSelectRegion = (event) => {
    this.setState({
      region: event.target.value 
    });
  };

  callAddPersonAPI = () =>{
    const { /*phoneNumber,*/ email, organizationData } = this.state;
    const person = {}
    person.name = this.state.firstName;
    person.owner_id = 12055462;
    person.org_id = organizationData && organizationData.data.id;
    person.email = [
      {
        "value": `${email}`,
        "primary": true,
        "label": "personal"
      }
    ];
    // person.phone = [
    //   {
    //     "value": `${phoneNumber}`,
    //     "primary": true,
    //     "label": "personal"
    //   }
    // ];
    this.addPersonApi(person)
  }

  postOrganizaion = async(postData)=>{
    const authToken = "5ae65531fa59b4e58942a90f5f98b14cbe877818";
    let url = `https://api.pipedrive.com/v1/organizations?api_token=${authToken}`;
    await fetch(url, {
      method:"POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              this.setState({ organizationData: data },()=>this.callAddPersonAPI())
              
            } else {
              toast.error(data.data.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  addPersonApi = async(postData)=>{
    const authToken = "5ae65531fa59b4e58942a90f5f98b14cbe877818";
    let url = `https://api.pipedrive.com/v1/persons?api_token=${authToken}`;
    await fetch(url, {
      method:"POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              this.setState({ personData: data },()=>this.dealsApiCall())
              
            } else {
              toast.error(data.data.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  
  postDeals = async(postData)=>{
    const authToken = "5ae65531fa59b4e58942a90f5f98b14cbe877818";
    let url = `https://api.pipedrive.com/v1/deals?api_token=${authToken}`;
    await fetch(url, {
      method:"POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              this.setState({ dealsData: data },()=>this.sendEmail());
            } else {
              toast.error(data.data.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  sendEmail = async()=>{
    this.props.history.push('/thankyou');
    const emailData = {full_name:this.state.firstName,email:this.state.email,phone:this.state.phoneNumber,company:this.state.companyName,size:this.state.companySizePointKey,region:this.state.region}
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url =BASE_URL + "/v1/contactEmail";
    await fetch(url, {
      method:"POST",
      headers: {
        authtoken: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            window.console.log("data",data);
            // if (res.status === 200 || res.status === 201) {

            // } else {
            //   toast.error(data.data.message);
            // }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  onChangeInput = (e) => {
    const email = e.target.value;
    this.setState({
      [e.target.name]: e.target.value,
    });
    if(e.target.name=="email"){
      this.setState({
        isValidEmail: this.validateEmail(email),
      });
    }
  };

  validateEmail = (email) => {
    // Regular expression to match a simple email format
    const emailRegex = /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/;

    // Check if the email matches the regex
    return emailRegex.test(email);
  };

  render() {
    const { contactUsInfo, isLoading,  contactSuccess, contactPopUpStatus } = this.props;
    const { contactStatus, companySizePointKey, companysize,/*value,*/ /*phoneNumber,*/ email, firstName, isValidEmail } = this.state;
    return (
      <Layout>
        {isLoading && <Waiting />}
        <FormContainer>
          <CustomForm onSubmit={(e) => this.submitForm(e)} id='contact-form'>
            {
              contactUsFormData.map((field, index) => (

                <SectionContainer key={field.name + index} fullWidth={field.label === 'Your Message'} 
                  padding={index % 2 == 0}>
                  <StyledLabel>{field.label}<span>*</span></StyledLabel>
                  <StyledInput
                    type='text'
                    name={field.name}
                    onBlur={(e) => this.onBlur(e)}
                    onChange={(e) => this.onChangeInput(e)}
                    defaultValue={_.get(contactUsInfo, field.name, '')}
                    onKeyPress={(e) => (field.name ==='phone' || field.name === 'size') && this.isNumberKey(e)}
                    maxLength={(field.name === 'phone' || field.name === 'size') ? 12 : -1}
                    required
                  />
                </SectionContainer>
                
              ))
            }
            {/* <SectionContainer>
              <StyledLabel>Phone Number<span>*</span></StyledLabel>
            </SectionContainer>
            <SectionContainer>
              <PhoneInput
                value={value}
                name="phone"
                onChange= {this.onChangePhoneNumber}
                style={{"width":"330px", "margin":"0px", "border-radius": "3px"}}
              >
              </PhoneInput>
            </SectionContainer> */}
            {
              contactUsFormDataNew.map((field, index) => (

                <SectionContainer key={field.name + index} fullWidth={field.label === 'Your Message'} 
                  padding={index % 2 == 0}>
                  <StyledLabel>{field.label}<span>*</span></StyledLabel>
                  <StyledInput
                    type='text'
                    name={field.name}
                    onBlur={(e) => this.onBlur(e)}
                    onChange={(e) => this.onChangeInput(e)}
                    defaultValue={_.get(contactUsInfo, field.name, '')}
                    onKeyPress={(e) => (field.name ==='phone' || field.name === 'size') && this.isNumberKey(e)}
                    maxLength={(field.name === 'phone' || field.name === 'size') ? 12 : -1}
                    required
                  />
                  {field.name=="email"&&!isValidEmail&&email&&email.length>0&&<p style={{color:"#F6797D", textAlign:"left"}}>Please enter business email</p>}
                </SectionContainer>
                
              ))
            }
            <SectionContainer>
              <StyledLabel>Company Size<span>*</span></StyledLabel>
              <select name="company_size" id="region" style={{width:"100%", height:"50px", padding:"0 20px"}} onChange={this.onSelectPoints}>
                <option value="">Select an option</option>
                {companysize.map((item, index) => (
                  <option value={item.key} key={index}>{item.key}</option>))}
              </select>
            </SectionContainer>
            <SectionContainer>
              <StyledLabel>Region<span>*</span></StyledLabel>
              <select name="region" id="region" style={{width:"100%", height:"50px", padding:"0 20px"}} onChange={this.onSelectRegion}>
                <option value="">Select an option</option>
                {region.map((item, index) => (
                  <option value={item.key} key={index}>{item.key}</option>))}
              </select>
            </SectionContainer>
            <div className="buttonContainer">
              <CustomButton
                type="submit"
                disabled={!companySizePointKey||!companysize|| !email|| !firstName||!this.state.region||!isValidEmail}
              >SUBMIT</CustomButton>
            </div>
          </CustomForm>
        </FormContainer>
        <ContactSuccessPopUp
          showModal={contactStatus && contactPopUpStatus}
          onClose={this.onClosePopUp}
          title={"Message Received!"}
          subContent={contactSuccess}
        />
        <ToastContainer />
      </Layout>
    )
  }
}

ContactUsForm.propTypes = {
  contactUsInfo: PropTypes.object,
  saveContactUsInfo: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  submitContactForm: PropTypes.func.isRequired,
  contactError: PropTypes.string,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  contactSuccess: PropTypes.string,
  contactPopUpStatus: PropTypes.bool
};

const mapStateToProps = (state) => ({
  contactUsInfo: state.auth.contactUsInfo,
  contactError: state.auth.contactError,
  isLoading: state.auth.isLoading,
  contactSuccess: state.auth.contactSuccess,
  contactPopUpStatus: state.auth.contactPopUpStatus,
});

const mapDispatchToProps = (dispatch) => ({
  saveContactUsInfo: (name, value) => dispatch(saveContactUsInfo(name, value)),
  submitContactForm: (history) => dispatch(submitContactForm(history))
});

export default withValidations(connect(mapStateToProps, mapDispatchToProps)(ContactUsForm));
